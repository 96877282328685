@import "~src/assets/scss/_variables";
@import "~src/assets/scss/_theme";
@import '~@vime/core/themes/default.css';
@import '~@vime/core/themes/light.css';
@import '~ngx-bar-rating/themes/br-default-theme';




@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;600;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html{height:100%;}
body{margin: 0;min-height:100%;height:100%;overflow:hidden;}

p {
  user-select: none;
}

body {
  font-family: "Inter";
  color: #212529 !important;

}

.game-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  background-image: url(~src/assets/img/bg-gradient.jpeg);
  .game-bg-left-side {
    width: 50%;
    height: 100%;
    background-image: url(~src/assets/imgx/bg-left-girl.png);
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 50px;
  }

  .game-bg-right-side {
    width: 50%;
    height: 100%;
    background-image: url(~src/assets/imgx/bg-right-girl.png);
    background-repeat: no-repeat;
    background-position-x: right;

    @media (max-width: 1600px) {
      background-position-x: left;
    }
  }
}

ul,
ol {
  list-style-type: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.d-none {
  display: none !important;
}

.wrapper {
  max-width: $max-screen;
  height: 100%;
  margin: 0 auto;
  position: relative;
  background: #fff;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.w-100 {
  width: 100%;
}

.content-box {
  height: calc(100% - 147px);
  flex: 1 1 auto;
  padding: 20px 10px 5px;

  // overflow: auto;
  @media (max-width: 400px) {
    padding: 20px 10px 5px;
  }
}

.attachment {
  background: url("~src/assets/img/bg.png");
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  &.no-gutter {
    padding: 0;
  }
}

.btn {
  border-radius: 100px;
  font-size: 16px;
  line-height: 1.171875;
  font-weight: 600;
  vertical-align: middle;
  text-transform: uppercase;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  @media(max-width:320px) {
    height: 50px;
  }
}

.btn-block {
  width: 100%;
  display: flex;
}

.btn-uppercase {
  text-transform: uppercase;
}

.btn-grey {
  color: #777d87;
  background: rgba(120, 117, 136, 0.2);
}

.btn-green {
  color: #fff;
  background: rgba(34, 193, 79, 1);
}

.btn-blue {
  background: linear-gradient(180deg, #3b5998 0%, #355392 100%);
  box-shadow: 0px 2px 10px rgba(59, 89, 152, 0.55);
  color: #fff;
}

.btn-purchased {
  display: inline-flex;
  align-items: center;
  color:  $ok-color;
  text-transform: uppercase;
  background-color: rgba($color: $ok-color, $alpha: .1);
  font-size: 15px;
  line-height: 33px;
  padding: 3px 15px;
  font-weight: 600;
  text-align: center;
  border-radius: 500px;
  box-shadow: 0 0 0 2px $ok-color inset;

  &>svg {
    margin-left: 8px;
  }
}

.btn-primary {
  // background: linear-gradient(155.33deg, #ffa183 8.78%, #ff3975 88.79%);
  // box-shadow: 0px 4px 10px rgba(255, 65, 117, 0.5);
  color: #fff;
}

.btn-white {
  background-color: #fff;
  color: #000;
}

.form-control {
  width: 100%;
  border: none;
  background: rgba(153, 135, 254, 0.1);
  border-radius: 100px;
  color: $primary-text-color;
  height: 50px;
  padding: 0 30px 0 20px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;

  &:focus {
    outline: none;
  }

  &.error {
    border: 3px solid rgba(252, 53, 53, 0.15);
  }
}

textarea.form-control {
  padding: 10px;
  height: 100px;
  border-radius: 25px;
}

.form-group {
  margin-bottom: 10px;
}

.form-offset {
  margin-bottom: $offset-form;
}
.form-offset-double {
  margin-bottom: $offset-form*2;
}

.form-group-hidden {
  display: none;
}

.form-info {
  font-size: 15px;
  line-height: 20px;
  color: #333d49;
  font-weight: 600;
  text-align: center;
}

.form-tab {
  display: flex;
  width: 213px;
  margin: 0 auto 34px;
  justify-content: space-between;

  .tab {
    color: #4e5682;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    opacity: 0.4;
    position: relative;

    &.active {
      // opacity: 1;
      // color: $primary-color;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        background-color: currentColor;
        width: 11px;
        height: 3px;
        border-radius: 2px;
      }
    }
  }
}


// router-outlet ~ * {
//   position: absolute;
//   height: 100%;
//   width: 100%;
// }

.content {
  display: none;

  &.active {
    display: block;
  }
}

.forgot-link {
  color: $primary-color;
  font-weight: 600;
  padding: 0;
  border: none;
  border-bottom: 2px solid $primary-color;
  background-color: transparent;
}

.btn-icon {
  margin-right: 12px;
}

.prize {
  color: #ffdf72;
  font-size: 18px;
  line-height: 21px;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
  margin-left: 12px;

  img {
    width: 33px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
  }
}

//////////////  FORM END ////////////////////////
.list-group {
  margin-bottom: 25px;
}

.list-group-item {
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #333d49;
  font-size: 16px;
  line-height: 23px;
  font-weight: bold;
  background: #ffffff;
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  height: 60px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.list-icon {
  margin-right: 21px;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 555;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: rgba(0,0,0,0.4);
  animation: animateOverlay 0.4s;

  @keyframes animateOverlay {
    from {
      background: rgba(0,0,0,0);
    }

    to {
      background: rgba(0,0,0,0.4);
    }
  }
}


html,
body {
  height: 100%;
}

.modal-container {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;

  &.animate-bottom {
    animation: animatebottom 0.4s;
  }

  @keyframes animatebottom {
    from {
      bottom: -600px;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }
}



.modal {
  background: #ffffff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  padding: 5px 5px 30px 5px;
  position: absolute; //fixed
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: $max-screen;
  width: 350px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  z-index: $z-modal;

  @media(max-width:320px) {
    width: 300px !important;
  }

  .modal-head {
    font-size: 21px;
    line-height: 25px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #323b46;
    background: #f5f3ff;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;

    &--warning {
      color: $red-color;
      background: rgba(255, 85, 85, 0.1);
    }

    &--info {
      color: #004085;
      background-color: #cce5ff;
    }

    &--light {
      color: #888888;
      background-color: #fff;
      font-size: 16px;
      font-weight: bold;
      font-family: Tahoma, Arial, Helvetica, sans-serif;
      margin-bottom: 0;
    }
  }

  .timer-box {
    margin-bottom: 12px;
  }

  .item-img {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    padding: 4px;
    margin: 0 auto 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .timer {
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    color: #ed403a;
    display: flex;
    align-items: center;
    justify-content: center;
    &.timer-info {
      color: #004085;
    }
  }

  .circle {
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
    background-color: rgba($color: #9987fe, $alpha: 0.1);
    border-radius: 50%;
    padding: 16px;
    position: relative;
    &>div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba($color: #f23232, $alpha: 0.12);
      padding: 9px;

      &>div {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #d7375e 0%, #f25d54 100%);
      }
    }
  }

  .info-text {
    font-weight: bold;
    text-align: center;
  }

  .info-img {
    display: block;
    margin: 0 auto;
  }

  .modal-body {
    // width: 270px;
    padding: 0 20px;
    margin: 0 auto;
  }

  .modal-content {
    background-color: #fff;
    padding: 0 20px;
    max-height: 400px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 48px;
      border-radius: 15px;
      background-color: #f5f3ff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 15px;
    }
  }

  .list-item {
    color: $primary-text-color;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 13px;
  }

  .list-item-content {
    font-size: 15px;
    line-height: 23px;
    color: $primary-text-color;

    p {
      margin-bottom: 13px;
    }
  }

  .prev,
  .close {
    width: 40px;
    height: 40px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #ffffff;
    border-radius: 10px;
    position: absolute;
    color: $primary-color;
  }

  .prev {
    left: 10px;
  }

  .close {
    right: 10px;
  }

  .text {
    color: $primary-text-color;
    font-size: 16px;
    line-height: 1.4375;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 130px;
    }
  }

  &--congratulations {
    background-image: url(assets/img/pattern-bg.png),
      linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    box-shadow: 0px 6px 15px rgba(252, 196, 91, 0.3);
    border: none;

    .text {
      color: #fff;

      .reward-current {
        font-size: 26px;
        line-height: 31px;
        font-size: 600;
        color: #ffdf74;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.19);
      }
    }

    .btn-primary {
      box-shadow: none;
      border: 4px solid #fff;
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
      height: 62px;
    }

    .modal-head {
      background: rgba(47, 29, 99, 0.2);
      border: none;
      color: #fff;
    }

    .circle {
      background: rgba(255, 255, 255, 0.12);

      &>div {
        background: rgba(255, 255, 255, 0.35);

        &>div {
          background: transparent;
        }
      }
    }
  }

  &.modal-white-headed {
    width: 90%;
    max-width: 420px;
    background:  linear-gradient(180deg, #fafbfb 25%, #f1f5f9 75%);
    .modal-head {
      font-size: 18px;
      color: #888;
      background: none;
      margin-bottom: 0;
    }
  }
}

.lead-text {
  text-align: center;
  margin: 20px 10px;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner{
  position: relative;
  color: rgba(0,  0,  0,  0)
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mt30 {
  margin-top: 30px;
}

.message-list {
  .message-list-item {
    height: 95px;
    background: #ffffff;
    box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    padding: 20px 15px;
    display: flex;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    &--lock {
      margin-bottom: 20px !important;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: 22px;
        background: linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
      }
      .message-list-item__body {
        filter: blur(4px);
      }
    }
    &--lock-soft {
      margin-bottom: 20px !important;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: 22px;
        background: linear-gradient(155.33deg, #2d97f4 8.77%, #495be5 88.79%);

      }
    }
    &.promo-chat {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: 22px;
        background: $magenta; //linear-gradient(155.33deg, rgb(73, 212, 143) 8.77%, rgb(62, 209, 62) 88.79%);
      }
    }

    &.promo-chat.onlyfans {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -2px;
        border-radius: 22px;
        background: linear-gradient(155.33deg, rgb(104, 186, 233) 8.77%, rgb(43, 139, 236) 88.79%);
      }
    }
    .avatar {
      width: 55px;
      height: 55px;
      object-fit: cover;
      border-radius: 50%;
    }
    &.bonus-item {
      padding: 15px 15px 15px 50px;

      .avatar-box {
        width: 90px;
      }

      &.received-reward {
        opacity: 0.5;
      }
    }

    .promo-click-area {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% + 15px);
      display: block;
      z-index: 199;
      color: transparent;
    }
  }
  .message-list-item__content {
    flex: 1 1 auto;
  }
  .avatar-box {
    margin-right: 15px;
    position: relative;
    flex:none;
  }
  .badge {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid #ffffff;
    position: absolute;
    bottom: 0;
    right: 3px;
    &--success {
      background: #2ad870;
    }
    &--warning {
      background: #d8bc2a;
    }
    &--danger {
      background: #d8492a;
    }
  }
  .message-list-item__head {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
  }
  .message-list-item__body {
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    color: rgba(51, 61, 73, 0.75);
    position: relative;
    padding-right: 10px;
  }
  .name {
    color: $primary-text-color;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
  .time {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: #c9c6d3;
    margin-left: auto;
  }
}

.btn-erogold-price{
  padding-left: 25px !important;
  span{
    position: relative;
    &::before{
      content: "";
      position: absolute;
      left: -25px;
      top: 0;
      width: 20px;
      height: 20px;
      background: url('assets/img/erogold.svg') no-repeat;
    }
  }
}

.transparent-modal{
  .swiper-pagination-bullets  {
    height: 20px;
    top: 0;
    .swiper-pagination-bullet{
      width: 6px;
      height: 3px;
      border-radius: 0;
      background: rgba(255,255,255,0.5);
    }
  }
}

.display-flex {
  display: flex;
}

.tabs {
  display: flex;
  justify-content: center;
  .tab{
    &.gems-tab,
    &.secret-chats-tab {
      height: 27px;
      line-height: 27px;
      text-align: center;
      color: $light-grey;
      font-family: Inter;
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      position: relative;
      text-decoration: none;
      background: #F3F3F2;
      box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.06);
      border-radius: 5px;

      &.active {
        color: #efefef !important;
        background: linear-gradient(321.78deg, #FF8F3F -8.19%, #FFC93F 88.65%);
        border-radius: 5px;
        border: none;
        box-shadow: none;
        transform: scale(1.06);
        z-index: 10;
      }
    }

    &.gems-tab {
      // background-image: url(~src/assets/img/gems-btn-inactive.png) !important;
      width: 120px;
      margin-right: -5px;

      &.active {
        // background-image: url(~src/assets/img/gems-btn-active.png) !important;
      }
    }

    &.secret-chats-tab {
      // background-image: url(~src/assets/img/secret-chats-btn-inactive.png) !important;
      width: 120px;
      box-shadow: inset -2px 2px 0px rgba(0, 0, 0, 0.06);

      &.active {
        // background-image: url(~src/assets/img/secret-chats-btn-active.png) !important;
      }
    }
  }
}


.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.height-auto {
  height: auto !important;
}


.btn-get-rewards, .btn-run-quest {
    padding: 5px;
    width: 150px;
    border-radius: 120px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    &.btn-get-rewards {
      background: linear-gradient(155.33deg, #2be47e 8.77%, #33b439 88.79%) !important;
    }
    &.btn-run-quest {
      background: linear-gradient(155.33deg, #ffc632 8.77%, #e54952 88.79%);
    }
}

.ad-separator {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
     // color: #555;
    text-align: center;
    &:before, &:after {
      content: "";
      position: relative;
      display: inline-block;
      width: 33%;
      height: 2px;
      vertical-align: middle;
      background: #999;
      background-clip: content-box;
    }
    &:before {
      left: -0.5em;
      margin: 0 0 0 -50%;
    }
    &:after {
      left: 0.5em;
      margin: 0 -50% 0 0;
    }
}

.btn {
  position: relative;
}


.btn-blue-green {
  background:  linear-gradient(130deg, #5494d5 16%, #35d562 40%);
  color: #fff;
  position: relative;
  box-shadow: 6px 6px 10px #b5fdbe;
  // font-size: 20px;
  font-weight: 600;
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  min-width: 150px;
  &.no-shadow {
    box-shadow: none;
  }
  &.with-crystal::after {
    content: '';
    background: url(~src/assets/img/gem.png) no-repeat;
    position: absolute;
    width: 30px;
    height: 30px;
    background-size: contain;
    left: 16px;
    top: 16px;
  }
}
.btn-red {
  background-color: $reddish;
  color: #fff;
  min-width: 170px;
  border-radius: 20px;
  font-weight: bold;
}
.btn-red-invert {
  color: $reddish;
  background-color: #fff;
  min-width: 170px;
  border-radius: 20px;
  font-weight: bold;
}

.button-cost-badge {
  position: absolute;
  background: red;
  color: #fff;
  top: -10px;
  left: 32px;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.img-inline-button {
  width: 25px;
  vertical-align: middle;
  margin: 3px;
}
.inline-gem-img-small{
  width: 18px;
  vertical-align: middle;
}



.tutorial-layer {
  &.step1,
  &.step2,
  &.step6,
  &.step8 {
    pointer-events: none;

    .all-chats-container & {
      pointer-events: auto;
    }
  }

}





.swiper-pagination-bullets{
  width: 20% !important;
  left: auto !important;
  right: 0;
}











// SHOP REDESIGN
.red-cross-line {
  position: absolute;
  transform: rotate(-23deg);
  width: 49px;
  height: 1px;
  background-color: red;
  left: 33px;
  top: 13px;
}


.old-price {
  font-size: 14px;
  font-weight: 300;
  font-family: Inter;
}
.new-price {
  font-size: 15px;
  font-weight: 700;
  font-family: Inter;
}
