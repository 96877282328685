/**
  Переменные для отступов
 */

 $offset-1: 8px;
 $offset-2: 16px;
 $offset-3: 24px;
 $offset-4: 32px;
 $offset-5: 40px;
 $offset-6: 48px;

 $offset-form: 20px;

 /**
   Переменные для цветов
  */
 $black: #231f20;
 $primary-text-color: #333d49;
 $orange: #f7941f;

 $primary-color: #ff826a;
 $secont-theme: #9987ff;
 $defaul-gradient: red;
 $red-color: #ff5555;
 $ok-color: rgb(97, 172, 53);
 $magenta: #da6ab9;

 $reddish: #f92d6d;
 $max-screen: 520px;
 /**
   Переменные для шрифтов
  */

 $font-size-1: 12px;
 $font-size-2: 16px;
 $font-size-3: 18px;
 $font-size-4: 24px;
 $font-size-5: 36px;

 $z-modal: 556;
 $z-overlay: 555;
 $z-tutorial: 556;
 $z-tob-bar: 333;




 // NEW STYLES
 $salad-green: #79DA18;
 $violet: #7064FF;
 $orange: #FD9C0B;
 $light-grey: #7D7D7D
